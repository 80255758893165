/** @jsx jsx */

import React, { useContext } from "react" // eslint-disable-line no-unused-vars
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import Box from "./box"
import Icon from "./icon"
import Text from "./text"

import { BulletListContext } from "./bullet-list"

/**
 * Use this component for Bullets, use this standalone or together with
 * `BulletList` which provides context to change all colors, icons, etc. for
 * all children
 */
const Bullet = ({ children, icon, color, size, variant, ...restProps }) => {
  const { theme } = useThemeUI()
  const { bulletSize, bulletColor, bulletIcon } =
    useContext(BulletListContext) || false

  const sizeFromProps = bulletSize || size
  const calculatedLineHeight = Array.isArray(sizeFromProps)
    ? sizeFromProps.map((value) => `${theme.fontSizes[value] * 1.5}px`)
    : `${theme.fontSizes[size] * 1.5}px`

  return (
    <Box display="flex" {...restProps}>
      <Box display="flex" alignY="center" height={calculatedLineHeight}>
        {bulletIcon || icon ? (
          <Icon
            icon={bulletIcon || icon}
            size={calculatedLineHeight}
            color={bulletColor || color}
            sx={{ flexShrink: 0 }}
          />
        ) : (
          <Box
            flexShrink={0}
            borderRadius="full"
            width="0.4em"
            height="0.4em"
            bg={bulletColor || color}
          />
        )}
      </Box>
      <Text as="span" size={bulletSize || size} ml={3}>
        {children}
      </Text>
    </Box>
  )
}

Bullet.propTypes = {
  /** element placed in front of the text */
  variant: PropTypes.oneOf(["dot", "checkmark"]),
  /** dot color  */
  color: PropTypes.string,
  /** font size size */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}

Bullet.defaultProps = {
  variant: "dot",
  color: "text",
  size: 2,
}

export default Bullet
